import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import WarningIcon from '@mui/icons-material/Warning'

interface UpgradeDialogProps {
  appNeedsUpdate: boolean
}

export const UpgradeDialog = ({ appNeedsUpdate }: UpgradeDialogProps) => {
  const handleRestartClick = () => {
    window.location.reload()
  }

  return (
    <Dialog
      open={appNeedsUpdate}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon
            fontSize="medium"
            sx={theme => ({
              color: theme.palette.warning.main,
              mr: theme.spacing(1),
            })}
          />
          Update needed
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        সঠিকভাবে কাজ করার জন্য, BD Chat আপডেট করা প্রয়োজন। আপডেটটি ইতিমধ্যেই পটভূমিতে ইনস্টল করা হয়েছে৷ আপনাকে যা করতে হবে তা হল পৃষ্ঠাটি পুনরায় লোড করুন বা নীচে "Refresh" এ ক্লিক করুন৷
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRestartClick} autoFocus>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  )
}
