import React, { useEffect, useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Tooltip from '@mui/material/Tooltip'
import { v4 as uuid } from 'uuid'

import { routes } from 'config/routes'
import { ShellContext } from 'contexts/ShellContext'
import { PeerNameDisplay } from 'components/PeerNameDisplay'
import { ReactComponent as Logo } from 'img/logo.svg'

interface HomeProps {
  userId: string
}

export function Home({ userId }: HomeProps) {
  const { setTitle } = useContext(ShellContext)
  const [roomName, setRoomName] = useState(uuid())
  const navigate = useNavigate()

  useEffect(() => {
    setTitle('BD Chat')
  }, [setTitle])

  const handleRoomNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setRoomName(value)
  }

  const handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    navigate(`/public/${roomName}`)
  }

  return (
    <Box className="Home">
      <main className="mt-6 px-4 max-w-3xl text-center mx-auto">
        <Link to={routes.ABOUT}>
          <Logo className="px-1 pb-4 mx-auto max-w-md" />
        </Link>
        <form onSubmit={handleFormSubmit} className="max-w-xl mx-auto">
          <Typography sx={{ mb: 2 }}>
            Your current user name:{' '}
            <PeerNameDisplay paragraph={false} sx={{ fontWeight: 'bold' }}>
              {userId}
            </PeerNameDisplay>
          </Typography>
          <FormControl fullWidth>
            <Tooltip title="Default room names are randomly generated client-side">
              <TextField
                label="Room name"
                variant="outlined"
                value={roomName}
                onChange={handleRoomNameChange}
                size="medium"
                
              />
            </Tooltip>
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            sx={{
              marginTop: 2,
            }}
          >
            Go to chat room
          </Button>
        </form>
      </main>
      <Divider sx={{ my: 2 }} />
      <Box className="max-w-3xl text-center mx-auto px-4">
        <Typography variant="body1">
        এটি একটি যোগাযোগের টুল যা বিনামূল্যে, সরলতা এবং নিরাপত্তার জন্য ডিজাইন করা হয়েছে। আপনার এবং আপনার অনলাইন সহকর্মীদের মধ্যে সমস্ত যোগাযোগ এনক্রিপ্ট করা হয়েছে৷ আপনি চলে গেলে আপনার কথোপকথনের কোন চিহ্ন নেই।
        
        </Typography>

        <Typography variant="body1" sx={{ textAlign: 'center' }}>
        Please{' '}
        <MuiLink
          href="https://bd-chat.vercel.app/about"
          target="_blank"
        >
          Learn More About It
        </MuiLink>
        .
      </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box className="max-w-3xl text-center mx-auto px-4">
        <Typography variant="body1">
        ⚠️ Disclaimer: If any user does any kind of illegal activities through this website. He or She or Them will have to take the full responsibility for their illegal activities. And It is made clear that the Author of this website will not take any responsibility of the users illegal activities.
        
        </Typography>

        <Typography variant="body1" sx={{ textAlign: 'center' }}>
        Please{' '}
        <MuiLink
          href="https://bd-chat.vercel.app/disclaimer.html"
          target="_blank"
        >
          Learn More About It
        </MuiLink>
        .
      </Typography>
      </Box>


      <Tooltip title="Follow Me">
        <MuiLink
          href="https://facebook.com/MDTaseenKhanBD"
          target="_blank"
          sx={{ display: 'block', textAlign: 'center', color: '#fff' }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Open menu"
            sx={{ mx: 'auto' }}
          >
            <FacebookRoundedIcon sx={{ fontSize: '2em' }} />
          </IconButton>
        </MuiLink>
      </Tooltip>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        Designed By{' '}
        <MuiLink
          href="https://md-taseen-khan.vercel.app"
          target="_blank"
        >
          MD Taseen Khan
        </MuiLink>
      </Typography>
    </Box>
  )
}
